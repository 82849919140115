<template>
  <div id="openAccount">
    <div class="content-box">
      <div class="inner">
        <div class="liveaccount-box">
          <div class="form-box demo-box" v-if="!success">
            <div class="left">
              <el-steps direction="vertical">
                <el-step icon="red"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="blue"></el-step>
                <el-step icon="red"></el-step>
              </el-steps>
            </div>
            <div class="right">
              <h3>{{ $t('openDemoAdditAcc.header') }}</h3>
              <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left" label-width="25%">
                <div class="box box-platform">
                  <el-form-item prop="tradingPlatform">
                    <div class="box-inner">
                      <ul>
                        <li
                          v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)"
                          :key="index"
                          :class="{ active: form.tradingPlatform == item.value }"
                          @click.prevent.stop="form.tradingPlatform = item.value"
                          :data-testid="item.value"
                        >
                          <div class="img" :class="item.value"></div>
                          <span>Meta Trader {{ item.label }}</span>
                        </li>
                      </ul>
                    </div>
                  </el-form-item>
                </div>
                <div class="box box-type">
                  <div class="box-inner">
                    <p class="title">{{ $t('openAdditAcc.chooseAccType') }}</p>
                    <el-form-item prop="accountType">
                      <ul v-if="form.tradingPlatform == 'mt4'">
                        <li
                          v-for="item in $config.openLiveAccount.mt4DemoChooseType(regulator)"
                          :key="item.value"
                          :class="{ active: form.accountType == item.value }"
                          @click.prevent.stop="form.accountType = item.value"
                          :data-testid="item.value"
                        >
                          <div class="img"></div>
                          <span>{{ item.label }}</span>
                        </li>
                      </ul>
                      <ul v-if="form.tradingPlatform == 'mt5'">
                        <li
                          v-for="item in $config.openLiveAccount.mt5DemoChooseType(regulator)"
                          :key="item.value"
                          :class="{ active: form.accountType == item.value }"
                          @click.prevent.stop="form.accountType = item.value"
                          :data-testid="item.value"
                        >
                          <div class="img"></div>
                          <span>{{ item.label }}</span>
                        </li>
                      </ul>
                    </el-form-item>
                  </div>
                </div>
                <div class="box box-currency">
                  <div class="box-inner">
                    <p class="title">{{ $t('openAdditAcc.chooseCurrency') }}</p>
                    <el-form-item prop="currency">
                      <ul>
                        <li
                          v-for="item in $config.openLiveAccount.chooseCurrency(regulator)"
                          :key="item.currency"
                          @click.prevent.stop="form.currency = item.currency"
                          :class="{ active: form.currency == item.currency }"
                          :data-testid="item.currency"
                        >
                          <div class="shadow-inner">
                            <div class="left"></div>
                            <div class="right">
                              <img :src="item.img" alt />
                              <p v-html="item.value"></p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </el-form-item>
                  </div>
                </div>
                <div class="box box-default">
                  <div class="box-inner">
                    <p class="title">{{ $t('openAdditAcc.chooseLeverage') }}</p>
                    <el-form-item prop="leverage">
                      <ul>
                        <li
                          v-for="item in $config.openLiveAccount.chooseLeverage(regulator)"
                          :key="item"
                          @click.prevent.stop="form.leverage = item"
                          :class="{ active: form.leverage === item }"
                          :data-testid="item"
                        >
                          <div class="shadow-inner">
                            <div class="left"></div>
                            <div class="right">
                              <p>{{ item }} : 1</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </el-form-item>
                  </div>
                </div>
                <div class="box box-default">
                  <div class="box-inner">
                    <p class="title">{{ $t('openAdditAcc.chooseBalance') }}</p>
                    <el-form-item prop="balance">
                      <ul>
                        <li
                          v-for="item in $config.openLiveAccount.chooseBalance(regulator)"
                          :key="item.value"
                          @click.prevent.stop="form.balance = item.value"
                          :class="{ active: form.balance === item.value }"
                          :data-testid="item.value"
                        >
                          <div class="shadow-inner">
                            <div class="left"></div>
                            <div class="right">
                              <p>{{ item.name }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </el-form-item>
                  </div>
                </div>
                <div class="last-box">
                  <div class="btn-box">
                    <el-button class="btn-blue" @click="submitClick" data-testid="submit">{{
                      $t('common.button.submit')
                    }}</el-button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <Result backUrl="homeDemo" :selfImg="true" v-else>
            <template v-slot:img>
              <div class="steps">
                <img src="@/assets/images/openAccount/step01.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step02.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step03.png" alt="" />
              </div>
            </template>
            <p v-html="$t('openDemoAdditAcc.succ')"></p>
          </Result>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';
import { apiCreateDemoAccount } from '@/resource';

export default {
  name: 'openDemoAccount',
  components: {
    Result
  },
  data() {
    return {
      form: {
        tradingPlatform: `mt4`,
        accountType: null,
        currency: null,
        leverage: null,
        balance: null
      },
      rules: {
        tradingPlatform: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.platErr')
          }
        ],
        accountType: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.accTypeErr')
          }
        ],
        currency: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.currError')
          }
        ],
        leverage: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.leverageErr')
          }
        ],
        balance: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.balanceErr')
          }
        ]
      },
      success: false
    };
  },
  methods: {
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form });
    },
    submitClick() {
      return this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true;
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/openAccount.scss';

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 165px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: 160px !important;
  }

  .el-step:nth-child(3) {
    flex-basis: 275px !important;
  }

  .el-step:nth-child(4) {
    flex-basis: 145px !important;
  }
  .el-step:nth-child(5) {
    flex-basis: calc(100% - 165px - 165px - 270px - 140px) !important;
  }
}

@media (max-width: 767px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 160px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 270px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: 360px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(4) {
      flex-basis: 240px !important;
      // flex-basis: 240px !important;
    }
    .el-step:nth-child(5) {
      flex-basis: calc(100% - 160px - 270px - 360px - 240px) !important;
    }
  }
}

@media (max-width: 400px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 190px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 270px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: 560px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(4) {
      flex-basis: 300px !important;
      // flex-basis: 240px !important;
    }
    .el-step:nth-child(5) {
      flex-basis: calc(100% - 190px - 270px - 560px - 300px) !important;
    }
  }
}
</style>
